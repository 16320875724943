import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PartheanModal from "../../components/PartheanModal/PartheanModal";
import { personasData } from "../../helpers/registration";
import {
  firebaseAuthListener,
  startFreeTrialAsync,
} from "../../services/firebase-auth";
import { captureSiteEvent } from "../../services/firestore";
import FinancialPersona from "../FinancialPersona/FinancialPersona";
import LearningPath from "../LearningPath/LearningPath";
import MeetYourCoach from "../MeetYourCoach/MeetYourCoach";
import RedirectToRegistration from "../RedirectToRegistration/RedirectToRegistration";
import StartFreeTrial from "../StartFreeTrial/StartFreeTrial";
import "./QuizResults.css";
import { calculatePersona } from "./utils";

const QuizResults = () => {
  const [loaded, setLoaded] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const location = useLocation();

  // Ensures session persistence
  useEffect(() => {
    firebaseAuthListener(setUserEmail, setLoaded, () => null);
  }, []);

  const searchParams = new URLSearchParams(location.search);
  const name = searchParams.get("name");
  const quizResponses: any = {};
  for (const [key, value] of Array.from(searchParams.entries())) {
    if (key !== "name") {
      quizResponses[key] = value;
    }
  }
  const persona = calculatePersona(quizResponses);
  const hasPersona = (persona && Object.keys(personasData).includes(persona)); 

  useEffect(() => {
    if (hasPersona) {
      captureSiteEvent('finish_quiz', userEmail);
    }
  }, []);

  if (loaded) {
    if (userEmail !== "") {
      return (
          <div style={{
            width: '100vw',
            position: 'relative'
          }}>
            <div style={{
              width: '100vw',
              paddingTop: 32,
              paddingBottom: 32,
              borderBottom: 'solid 0.5px #3d4043',
              position: 'fixed',
              backgroundColor: 'rgba(0, 0, 0, 0.8)'
            }}>
              <div style={{
                width: 900,
                maxWidth: '95vw',
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'flex',
                justifyContent: 'flex-start',
              }}>
                <img
                  src={'https://global-uploads.webflow.com/5e8176c1dad44d5cfe3067b4/60521bdf6d53d034fb5ae5e9_Parthean%20Logotype%20White.svg'}
                  style={{
                    height: 22
                  }}
                />
              </div>
            </div>
            <div style={{
              width: 900,
              maxWidth: '95vw',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}>
              <div style={{
                width: '100%',
                height: '60vh',
                paddingTop: '40vh',
              }}>
                <> 
                    <div className={"persona-title"}>
                      Hey<span className={'gradientColoredText'}>{` ${name || ''}.`}</span>
                      <br />
                      Let's get started.
                    </div>
                    <p style={{
                      color: '#9BA0A5',
                      textAlign: 'left',
                      marginTop: '35vh', 
                      fontSize: 14,
                    }}>
                      Keep scrolling...
                    </p>
                  </>
              </div>
              <FinancialPersona name={name || ""} persona={persona} />
              <MeetYourCoach hasPersona={hasPersona}/>
              <LearningPath userEmail={userEmail} />
              <StartFreeTrial />
            </div>
          </div>
      );
    } else {
      return <RedirectToRegistration />;
    }
  } else {
    return <div />;
  }
};

export default QuizResults;
