import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../AppState";
import { firebaseAuthListener, startFreeTrialAsync } from "../../services/firebase-auth";
import { captureSiteEvent } from "../../services/firestore";
import AppStoreButton from "./components/AppStoreButton";
import "./DownloadApp.css";
import { setupPixel } from "../../utils/fb_pixel";

const ReactPixel = setupPixel();
ReactPixel.track('PageView');

const STRIPE_REDIRECTED_HASH =
  "#75603236928bbc55c6d06335ecf15a32e7e3ec8030224a9e4abdeef1";

const DownloadApp = () => {
  const { isMobile } = useContext(AppContext);
  const [loaded, setLoaded] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const location = useLocation();

  // Ensures session persistence
  useEffect(() => {
    firebaseAuthListener(setUserEmail, setLoaded, () => null);
  }, []);
 
  useEffect(() => {
    if (userEmail !== "" && location.hash === STRIPE_REDIRECTED_HASH) {
      startFreeTrialAsync(userEmail).then(() => {
        ReactPixel.track('StartTrial', { email: userEmail })
        captureSiteEvent('trial_start', userEmail);
        console.log("free trial started");
      });
    }
  }, [userEmail]);

  if (loaded) {
    if (userEmail !== "") {
    return (
      <div style={{
        margin: isMobile ? "20px 0px" : "48px 0px",
        width: 500,
        maxWidth: '95vw',
        marginLeft: 'auto',
        marginRight: 'auto'
      }}>
        <img
          src={'https://global-uploads.webflow.com/5e8176c1dad44d5cfe3067b4/60521bdf6d53d034fb5ae5e9_Parthean%20Logotype%20White.svg'}
          style={{
            height: 22,
            marginBottom: 20
          }}
        />
        <div className={`persona-title`} style={{
          alignSelf: 'center',
          textAlign: 'center',
          marginRight: 0,
          marginBottom: 60,
        }}>Download the Parthean App to get started</div>
        <div>
          {isMobile ? null : (
            <img
              src={`${require("../../assets/images/parthean-app-qr-code.png")}`}
              alt="parthean-qr-code"
              style={{
                marginBottom: 20,
                width: 200,
                borderRadius: 10
              }}
            />
          )}
          {isMobile ? (
            <div className={`mobile-appstore-button-container`}>
              <a href="https://apps.apple.com/us/app/parthean/id1585842493">
                <AppStoreButton size={60} />
              </a>
            </div>
          ) : (
            <p className={`appstore-button-text`}>
              Scan with your iPhone <br /> camera to download
            </p>
          )}
        </div>
      </div>
    );
    } else {
      return <RedirectToRegistration />
    }
  } else {
    return <div />;
  }
};

// If user is not signed in, redirect to registration
const RedirectToRegistration = ({}: any) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/sign-up");
  }, []);

  return <div />;
};

export default DownloadApp;
