import "./SignUp.css";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../AppState";
import GradientTextButton from "../../components/GradientTextButton/GradientTextButton";
import PartheanModal from "../../components/PartheanModal/PartheanModal";
import { errorCodeToMessage, isValidEmail } from "../../helpers/registration";
import {
  firebaseAuthListener,
  firebaseCreateUserAsync,
  firebaseLogInAsync,
  firebaseLogOut,
} from "../../services/firebase-auth";
import RegistrationTextInput from "./components/RegistrationTextInput/RegistrationTextInput";
import SignUpButton from "./components/SignUpButton/SignUpButton";
import { setupPixel } from "../../utils/fb_pixel";
import { captureSiteEvent } from "../../services/firestore";

const ReactPixel = setupPixel();
ReactPixel.track('PageView');

const TYPEFORM_QUIZ_URL = "https://parthean.typeform.com/to/ux3VbbGz";

const SignUp = () => {
  const [loaded, setLoaded] = useState(false);
  const [userEmail, setUserEmail] = useState<string>("");

  useEffect(() => {
    firebaseAuthListener(setUserEmail, setLoaded, () => null);
  }, []);

  return loaded ? (
    <div className={'register-background'}>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw'
      }}>
      <PartheanModal
        content={
          userEmail ? (
            <AlreadySignedIn email={userEmail} />
          ) : (
            <DecoratedRegistration
              userEmail={userEmail}
              setUserEmail={setUserEmail}
            />
          )
        }
      />
      </div>
    </div>
  ) : (
    <div />
  );
};

export default SignUp;

const DecoratedRegistration = ({ userEmail, setUserEmail }: any) => {
  const { isMobile } = useContext(AppContext);

  return (
      <div className={`signup-container`}>
        {userEmail ? (
          <AlreadySignedIn
            email={userEmail || ""}
            setUserEmail={setUserEmail}
          />
        ) : (
          <Registration isMobile={isMobile} />
        )}
      </div>
  );
};

const AlreadySignedIn = ({ email }: any) => {
  const { isMobile } = useContext(AppContext);
  const navigate = useNavigate();

  return (
    <div
      className={`signedin-container`}
      style={{ padding: isMobile ? "0px 0px 16px 0px" : "0px 48px 16px 48px" }}
    >
      <p style={{ marginBottom: isMobile ? "16px" : "24px" }}>Welcome!</p>
      <p className={`signedin-body`}>Looks like you're already signed in as</p>
      <p className={`signedin-body`}>{email}</p>
      <div
        className={`signedin-buttons-container`}
        style={{ marginTop: isMobile ? "24px" : "36px" }}
      >
        <GradientTextButton
          label="Start Free Trial"
          gradientClass="teal-green-gradient-text"
          onClick={() => navigate("/get-started", { state: { email } })}
        />
        <GradientTextButton
          label="Not You?"
          gradientClass=""
          onClick={() => firebaseLogOut()}
        />
      </div>
    </div>
  );
};

const Registration = ({ isMobile }: any) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<any>("");
  const [isNewUser, setIsNewUser] = useState<boolean>(true);

  const goToGetStartedPage = (email: string) =>
    navigate("/get-started", { state: { email } });

  const goToTypeform = (email: string) => {
    window.location.href = `${TYPEFORM_QUIZ_URL}#email=${email}`;
  };

  const handleSubmit = (ev: any) => {
    ev.preventDefault();
    if (!email || !isValidEmail(email)) {
      setError(errorCodeToMessage("invalid_email"));
    } else if (!password || password.length < 8) {
      setError(errorCodeToMessage("invalid_password"));
    } else if (email && password) {
      if (isNewUser) {
        ReactPixel.track('CompleteRegistration', { email });
        captureSiteEvent('sign_up', email);
        firebaseCreateUserAsync(email, password, goToTypeform, setError);
      } else {
        firebaseLogInAsync(email, password, goToGetStartedPage, setError);
      }
    } else {
      setError(errorCodeToMessage("catch_all"));
    }
  };

  return (
    <form
      className={`registration-form`}
      style={{
        padding: isMobile ? "16px 0px" : "16px 48px 16px 48px",
      }}
      onSubmit={handleSubmit}
    >
      <div style={{
        color: 'white',
        fontSize: 26,
        marginBottom: 32
      }}>
        {isNewUser ? "Create an account" : "Welcome back"}
      </div>
      <RegistrationTextInput
        inputType="email"
        value={email}
        setValue={setEmail}
      />
      <RegistrationTextInput
        inputType="password"
        value={password}
        setValue={setPassword}
      />
      {error ? <p className={`registration-error`}>{error}</p> : null}
      <SignUpButton isNewUser={isNewUser} />
      <p
        className={`registration-is-new-body`}
        onClick={() => {
          setIsNewUser(!isNewUser);
        }}
      >
        {isNewUser ? "I already have an account" : "I do not have an account"}
      </p>
    </form>
  );
};
