import { db } from "./firebase";
import {
  collection,
  doc,
  DocumentData,
  onSnapshot,
  Query,
  query,
  where,
} from "firebase/firestore";
import axios from "axios";

export const getPaidUserRef = (email: string) => {
  return doc(db, "paid_users", email);
};

export const snapshotUserPlaybookByTemplateId = async (
  email: string,
  templateId: string,
  callback: any
) => {
  const q: Query<DocumentData> = query(
    collection(db, "user_action_bundles", email, "action_bundles"),
    where("template_id", "==", templateId)
  );
  return onSnapshot(q, callback);
};

const EVENT_TRACKER_URL = 'https://us-central1-parthean-platform.cloudfunctions.net/firestore-capture_site_event';

export const captureSiteEvent = async (
  event: string,
  email?: string,
) => {
  /*
  Available Events:
    parthean.com - site_visit, click_get_started 
    app.parthean - sign_up, finish_quiz, add_playbooks, register
    cloud functions - convert
  */
  axios.post(EVENT_TRACKER_URL, `event=${event}&email=${email}`);
}