import { FirebaseError } from "firebase/app";
import {
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  browserSessionPersistence,
} from "firebase/auth";
import { setDoc } from "firebase/firestore";
import { errorCodeToMessage } from "../helpers/registration";
import { getPaidUserRef } from "./firestore";

export const startFreeTrialAsync = async (email: string) => {
  console.log(email);
  return await setDoc(
    getPaidUserRef(email),
    { trial_started: Date.now(), paymentStatus: "TRIAL" },
    { merge: true }
  );
};

export const firebaseCreateUserAsync = async (
  email: string,
  password: string,
  thenNavigate: Function,
  setError: any
) => {
  const auth = getAuth();
  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      if (thenNavigate) {
        thenNavigate(userCredential.user.email);
      }
    })
    .catch((error: FirebaseError) => {
      setError(errorCodeToMessage(error?.code));
    });
};

export const firebaseLogInAsync = async (
  email: string,
  password: string,
  thenNavigate: Function,
  setLoginError: any
) => {
  const auth = getAuth();
  setPersistence(auth, browserSessionPersistence)
    .then(() => {
      return signInWithEmailAndPassword(auth, email, password);
    })
    .then((userCredential) => {
      if (thenNavigate) {
        thenNavigate(userCredential.user.email);
      }
    })
    .catch((error: FirebaseError) => {
      setLoginError(errorCodeToMessage(error?.code));
      try {
        signOut(auth);
      } catch (error) {
        console.log(error);
      }
    });
};

// Firebase auth listener to persist login upon refresh
export const firebaseAuthListener = async (
  setUserEmail: any,
  setChecked: any,
  setLoginError: any
) => {
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUserEmail(user.email);
      setChecked(true);
    } else {
      try {
        setUserEmail("");
        setChecked(true);
      } catch (error: any) {
        console.log(error);
      }
    }
  });
};

// Firebase auth sign out
export const firebaseLogOut = async () => {
  const auth = getAuth();
  try {
    auth.signOut();
  } catch (error) {
    console.log(error);
  }
};
