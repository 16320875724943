import { useContext } from "react";
import { AppContext } from "../../AppState";
import { personasData } from "../../helpers/registration";
import "./FinancialPersona.css";

const FinancialPersona = ({
  name,
  persona,
}: {
  name: string;
  persona: string;
}) => {
  const { isMobile } = useContext(AppContext);

  if (!Object.keys(personasData).includes(persona)) {
    return null;
  }

  const direction = isMobile ? "column" : "row";
  
  return (
    <div style={{
      minWidth: isMobile ? "100px" : "600px",
      width: '100%',
      height: '100vh',
      marginTop: 'auto',
      marginBottom: 'auto',
    }}>
      <div className={"persona-intro"} style={{marginTop: 75}}>
        We just analyzed your quiz results.
        <br />
        Based on the responses,
        <br />
        we calculated your <span className={'gradientColoredText'}>financial persona.</span>
      </div>
      <div className={`persona-info-container`}>
        <img
          src={`${require(`../../assets/images/personas/${persona.toLowerCase()}.png`)}`}
          alt="Persona"
          style={{width: '100%'}}
        />
      </div>
    </div>
  );
};

export default FinancialPersona;
