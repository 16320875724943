import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../AppState";
import PartheanModal from "../../components/PartheanModal/PartheanModal";
import RedirectToRegistration from "../RedirectToRegistration/RedirectToRegistration";
import "./Home.css";

const Home = () => {
  const navigate = useNavigate();
  const { isMobile } = useContext(AppContext);

  useEffect(() => {
    if (isMobile) {
      navigate("/auth");
    }
  }, [isMobile]);
  
  if (!isMobile) {
    return <RedirectToRegistration />;
  }
  return null;
};

export default Home;
