import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import "./App.css";
import { AppContext } from "./AppState";
import Home from "./screens/Home/Home";
import { setupPixel } from "./utils/fb_pixel";

const App = () => {
  const location: any = useLocation();
  const { pathname } = location;
  const [width, setWidth] = useState<number>(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  return (
    <AppContext.Provider value={{ isMobile }}>
      <div
        className="App"
        style={{
          backgroundColor: "#000000",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        {pathname === "/" ? <Home /> : <Outlet />}
      </div>
    </AppContext.Provider>
  );
};

export default App;
