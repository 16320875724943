import "./GradientTextButton.css";

const GradientTextButton = ({ onClick, label, gradientClass = "" }: any) => {
  return (
    <button
      className={`gradient-text-button`}
      onClick={onClick}
      style={gradientClass ? { width: "100%" } : { color: "white" }}
    >
      <p className={`${gradientClass} gradient-button-body`}>
        {label}
      </p>
    </button>
  );
};

export default GradientTextButton;
