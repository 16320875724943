export const registrationSteps: any = {
  1: "Review Your Persona",
  2: "Meet Your Coach",
  3: "Your Learning Path",
  4: "Personalize Your Trial",
  5: "Download the app",
};

export const personasData: any = {
  Resourceful: {
    description:
      "you gravitate towards unique financial prospects because you have an opportunistic money mindset.\nYou also enjoy saving money because security and financial goals are essential.",
    emoji: "💡",
  },
  Strategic: {
    description:
      "you constantly adjust between saving and spending because it gives you the flexibility you need.\nYou also gravitate towards unique financial prospects because you have an opportunistic money mindset.",
    emoji: "⚔",
  },
  Adventurous: {
    description:
      "you enjoy spending because it improves your life and helps you live it to its fullest potential.\nYou also gravitate towards unique financial prospects because you have an opportunistic money mindset.",
    emoji: "🪃",
  },
  Steadfast: {
    description:
      "you enjoy saving money because security and financial goals are essential.\nHowever, you still care about optimizing your current lifestyle and investments.",
    emoji: "🦾",
  },
  Responsible: {
    description:
      "you care a lot about balancing your current lifestyle with your future financial goals.\nBecause of this, you constantly adjust between saving and spending because it gives you the flexibility you need.",
    emoji: ":🔑",
  },
  Deliberate: {
    description:
      "you enjoy spending because it improves your life and helps you live it to its fullest potential.\nHowever, you still care about optimizing your current lifestyle and investments.",
    emoji: "🧮",
  },
  Modest: {
    description:
      "you're careful with your money and do diligence on financial opportunities that come your way.\nYou also enjoy saving money because security and financial goals are essential.",
    emoji: ":😇",
  },
  Prudent: {
    description:
      "you care a lot about balancing your future financial goals but not at the complete expense of your current lifestyle.\nBecause of this, you constantly adjust between saving and spending because it gives you the flexibility you need.",
    emoji: "🧭",
  },
  Skeptical: {
    description:
      "you enjoy spending because it improves your life and helps you live it to its fullest potential.\nYour spending tends to be conscientious, and you consider how each purchase improves your life today.",
    emoji: "🤔",
  },
  Economical: {
    description:
      "you make a conscientious effort to think through how financial decisions improve your life today and in the future.\nBecause of this, you enjoy saving money because security and financial goals are essential at the end of the day.",
    emoji: "🪙",
  },
  Vigilant: {
    description:
      "you care a lot about your future financial goals and security, even if it means sacrificing a bit in the short term.\nBecause of this, you constantly adjust between saving and spending because it gives you the flexibility you need.",
    emoji: "🦸",
  },
  Unconcerned: {
    description:
      "you enjoy spending because it improves your life and helps you live it to its fullest potential.\nYour spending tends to be more conscientious than impulsive, and you consider how each purchase improves your life today.",
    emoji: "🎈",
  },
};

export const suggestedPlaybooks: string[] = [
  "GBRv3NGcsdfMKngPgUUQ", // How to Start Investing
  "2hAT3MC52lH6cAcvsjfh", // Bye, Credit Card Debt!
  "xZWKWIJ4dWOScJfDa59H", // Boost Your Credit Score
  // 'EuwaZFwHONHXurvOchyB', // Automate Your Finances
  // 'aUfosgEwCUgDIi6R93uE', // Save for a Down Payment
  // 'yU0CnNGPg2XwsoNKQupx', // Start an Emergency Fund
];

export const isValidEmail = (emailString: string) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailString)) {
    return true;
  }
  return false;
};

export const errorCodeToMessage = (errorCode: any) => {
  switch (errorCode) {
    case "invalid_email":
      return "Please enter a valid email address.";
    case "invalid_password":
      return "Please enter a password that has 8 or more characters.";
    case "auth/user-not-found":
      return "This user does not exist. Please sign up.";
    case "auth/email-already-in-use":
      return "This email address is already in use. Please sign in.";
    case "auth/wrong-password":
      return "Incorrect email/password combination.";
    case "auth/too-many-requests":
      return "You have tried to log in too many times. Try again in a few minutes.";
    default:
      return "There was an error.";
  }
};

// firebase error codes: https://firebase.google.com/docs/reference/js/auth#autherrorcodes
