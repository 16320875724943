import "./RegistrationTextInput.css";

const placeholderText: any = {
  text: "Name",
  email: "Email",
  password: "Password",
};
const RegistrationTextInput = ({
  inputType,
  value,
  setValue,
  pattern,
  title,
}: any) => {
  return (
    <input
      className={`registration-text-input`}
      placeholder={placeholderText[inputType]}
      type={inputType}
      value={value}
      onChange={(ev) => setValue(ev.target.value)}
      pattern={pattern}
      title={title}
    />
  );
};

export default RegistrationTextInput;
