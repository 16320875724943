import { useContext } from "react";
import { AppContext } from "../../AppState";
import "./PartheanModal.css";

const PartheanModal = ({ content, style }: any) => {
  const { isMobile } = useContext(AppContext);
  return (
    <div
      className={`modal-container`}
      style={{
        ...style,
      }}
    >
      <div>
        <img
          src={`${require("../../assets/images/white-logo.png")}`}
          alt="Parthean Logo Text"
          className={`modal-img`}
        />
      </div>
      <div className={`modal-content`}>{content}</div>
    </div>
  );
};

export default PartheanModal;
