import { useCallback, useContext, useEffect, useState } from "react";
import {
  Question,
  QUESTION_TYPES,
  quizQuestions,
  calculatePersona,
} from "./question_data";
import "./PersonaQuiz.css";
import FinancialPersona from "../FinancialPersona/FinancialPersona";
import { IoCheckmark } from "react-icons/io5";
import { AppContext } from "../../AppState";
import { firebaseAuthListener } from "../../services/firebase-auth";
import { useNavigate } from "react-router-dom";

const PersonaQuiz = () => {
  const { isMobile } = useContext(AppContext);
  const [quizResponses, setQuizResponses] = useState<{ [index: number]: any }>(
    {}
  );
  const [questionIndex, setQuestionIndex] = useState<number>(-1);
  const [shouldFade, setShouldFade] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState("");

  // Gets an email if there is one, to save persona to.
  useEffect(() => {
    firebaseAuthListener(
      setUserEmail,
      (val: boolean) => null,
      () => null
    );
  }, []);

  useEffect(() => {
    setShouldFade(true);
    if (
      questionIndex >= 0 &&
      questionIndex < quizQuestions.length &&
      quizQuestions[questionIndex].type !== QUESTION_TYPES.INFO
    ) {
      setButtonDisabled(true);
    }
  }, [questionIndex]);

  const updateQuestion = useCallback(() => {
    setQuestionIndex(questionIndex + 1);
    setShouldFade(false);
  }, [questionIndex]);

  const updateResponse = useCallback(
    (response: any) => {
      setQuizResponses({
        ...quizResponses,
        [questionIndex]: response,
      });
    },
    [questionIndex]
  );

  const getPanelToDisplay = useCallback(() => {
    if (questionIndex === -1) {
      return (
        <div style={{ fontSize: isMobile ? 20 : 40 }}>
          {"Welcome to the Parthean Quiz"}
        </div>
      );
    } else if (questionIndex >= quizQuestions.length) {
      return <EndPanel userEmail={userEmail} quizResponses={quizResponses} />;
    }
    const question: Question = quizQuestions[questionIndex];
    if (question.type === QUESTION_TYPES.INFO) {
      return <InfoPanel question={question} />;
    } else {
      return (
        <>
          <p style={{ textAlign: "left" }}>
            {`Question ${questionIndex + 1} out of ${quizQuestions.length}`}
          </p>
          <QuestionPanel
            question={question}
            updateQuestion={updateQuestion}
            updateResponse={updateResponse}
            setButtonDisabled={setButtonDisabled}
          />
        </>
      );
    }
  }, [questionIndex]);

  return (
    <div
      className={`quiz-container ${shouldFade ? "fade-in" : ""}`}
      style={{ fontSize: isMobile ? 14 : 24 }}
    >
      {getPanelToDisplay()}
      <ContinueButton
        userEmail={userEmail}
        text={
          questionIndex === -1
            ? "Begin"
            : questionIndex >= quizQuestions.length
            ? "Get Started With Parthean"
            : "Continue"
        }
        quizEnded={questionIndex >= quizQuestions.length}
        disabled={buttonDisabled}
        updateQuestion={updateQuestion}
      />
    </div>
  );
};

const InfoPanel = ({ question }: { question: Question }) => {
  return <div className="info-container">{question.question}</div>;
};

const QuestionPanel = ({
  question,
  updateQuestion,
  updateResponse,
  setButtonDisabled,
}: {
  question: Question;
  updateQuestion(): void;
  updateResponse(response: any): void;
  setButtonDisabled(newDisabled: boolean): void;
}) => {
  const { isMobile } = useContext(AppContext);
  const [selectedAnswers, setSelectedAnswers] = useState<{
    [index: number]: boolean;
  } | null>(null);

  useEffect(() => {
    if (question.answers) {
      const initialSelected: any = {};
      question.answers.forEach((answer, index) => {
        initialSelected[index] = false;
      });
      setSelectedAnswers(initialSelected);
    }
  }, [question]);

  const updateSelected = useCallback(
    (newIndex: number) => {
      if (selectedAnswers == null) {
        return;
      }
      setButtonDisabled(false);
      let newSelected: { [index: number]: boolean } = {};
      if (question.type === QUESTION_TYPES.CHOICE) {
        Object.keys(selectedAnswers).forEach(
          (index) => (newSelected[Number(index)] = false)
        );
        newSelected[newIndex] = true;
      } else {
        newSelected = {
          ...selectedAnswers,
          [newIndex]: !selectedAnswers[newIndex],
        };
      }
      setSelectedAnswers(newSelected);
      const response = Object.keys(newSelected)
        .filter((index) => newSelected[Number(index)])
        .map((index) => question.answers[Number(index)].text);
      updateResponse(response);
    },
    [question, selectedAnswers]
  );

  if (selectedAnswers == null) {
    return null;
  }

  return (
    <div
      className="question-container"
      style={{ fontSize: isMobile ? 18 : 32 }}
    >
      {question.question}
      {question.answers.map((answer, index) => (
        <div
          key={`answers-${answer.text}`}
          className="pointable answer-option"
          onClick={() => updateSelected(index)}
        >
          <div className="answer-option-left">
            <div className="answer-option-letter">
              {String.fromCharCode(index + 65)}
            </div>
            {answer.text}
          </div>
          {selectedAnswers[index] ? (
            <IoCheckmark
              size={isMobile ? 18 : 32}
              style={{ justifySelf: "flex-end" }}
            />
          ) : (
            <div style={{ minWidth: isMobile ? 18 : 32 }} />
          )}
        </div>
      ))}
    </div>
  );
};

const EndPanel = ({
  userEmail,
  quizResponses,
}: {
  userEmail: string;
  quizResponses: { [index: number]: any };
}) => {
  const persona = calculatePersona(quizResponses);

  useEffect(() => {
    if (userEmail !== "") {
      // We can set the user's persona in firebase from here when we are ready to do that.
      console.log(userEmail, persona);
    }
  }, []);

  return <FinancialPersona name={""} persona={persona} />;
};

const ContinueButton = ({
  userEmail,
  text,
  quizEnded,
  disabled,
  updateQuestion,
}: {
  userEmail: string;
  text: string;
  quizEnded: boolean;
  disabled: boolean;
  updateQuestion(): void;
}) => {
  const { isMobile } = useContext(AppContext);

  return (
    <button
      className={`continue-button ${quizEnded ? "get-started" : ""}`}
      style={{ fontSize: isMobile ? 16 : 24 }}
      disabled={disabled}
      onClick={() => {
        if (!quizEnded) {
          updateQuestion();
        } else if (userEmail === "") {
          window.parent.location.href = "https://app.parthean.com/sign-up";
        } else {
          window.parent.location.href = "https://app.parthean.com/get-started";
        }
      }}
    >
      {text}
    </button>
  );
};

export default PersonaQuiz;
