import "./GetStartedButton.css";

const GetStartedButton = (props: any) => {
  return (
    <button className={`get-started-button`} {...props}>
      <p className={`get-started-button-text`}>GET STARTED</p>
    </button>
  );
};

export default GetStartedButton;
