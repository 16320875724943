const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;

export const parseTime = (timeString: string) => {
  const chunks = timeString.split(":");
  const secondChunks = chunks[2].split(",");
  const hours = parseInt(chunks[0], 10);
  const minutes = parseInt(chunks[1], 10);
  const seconds = parseInt(secondChunks[0], 10);
  const milliSeconds = parseInt(secondChunks[1], 10);

  return HOUR * hours + MINUTE * minutes + SECOND * seconds + milliSeconds;
};

export const convertSRTtoSubtitlesArray = (rawSubtitles: string) => {
  const subtitlesInArray = rawSubtitles.split("\n");
  const results: Array<any> = [];
  let newData: any = {};
  for (let i = 0; i < subtitlesInArray.length; i++) {
    const row = subtitlesInArray[i];
    if (row.includes("-->")) {
      const [start, end] = row.split(" --> ");
      newData.timeStampStart = parseTime(start);
      newData.timeStampEnd = parseTime(end);
    } else if (!Number.isNaN(row) && !Number.isNaN(parseFloat(row))) {
      if (newData?.hasOwnProperty("text")) {
        results.push(newData);
        newData = {};
      }
    } else if ("text" in newData) {
      newData.text += ` ${row}`;
    } else {
      newData.text = row;
    }
  }
  if (newData?.hasOwnProperty("text")) {
    results.push(newData);
  }
  return results;
};

export const findCurrentText = (
  subtitles: any,
  playbackInstancePosition: number
) => {
  for (let i: number = 0; i < subtitles?.length || 0; i++) {
    const subtitle = subtitles[i];
    if (
      playbackInstancePosition > subtitle?.timeStampStart - 500 &&
      playbackInstancePosition < subtitle?.timeStampEnd
    ) {
      return subtitle?.text;
    }
  }
  return "";
};
