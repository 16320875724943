import axios from "axios";

const CREATE_OR_UPDATE_PLAYBOOK_URL =
  "https://us-central1-parthean-platform.cloudfunctions.net/firestore-create_or_update_user_playbook";

export const assignUserPlaybookAsync = async (
  email: string,
  playbookId: string,
  userPlaybookId: string,
  selected: boolean
) => {
  try {
    return axios
      .post(
        CREATE_OR_UPDATE_PLAYBOOK_URL,
        `email=${email}&playbookId=${playbookId}&userPlaybookId=${userPlaybookId}&isDraft=${selected}&coachId=PERSONALIZED`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        return true;
      })
      .catch((e) => {
        console.log("error:", e);
        return false;
      });
  } catch (error: any) {
    console.log(error);
    return false;
  }
};
