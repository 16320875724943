import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export default useQuery;

const parseQueryParameters = (searchString: string | undefined = "") => {
  const queryParams: any = {};
  const searchStrings = searchString.replace("?", "").split("&");
  searchStrings.forEach((param: string) => {
    const [key, val]: any = param.split("=");
    queryParams[key] = val;
  });
  return queryParams;
};
