import "./SignUpButton.css";

const SignUpButton = ({ isNewUser }: any) => {
  return (
    <input
      className={`sign-up-button`}
      type="submit"
      value={isNewUser ? "Sign Up" : "Sign In"}
    />
  );
};

export default SignUpButton;
