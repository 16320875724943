import {
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  getDoc,
} from "firebase/firestore";
import { Dispatch, SetStateAction } from "react";
import { db } from "./firebase";

export const getVideoByRefAsync = async (
  docRef: DocumentReference<DocumentData>,
  setVideo: Dispatch<SetStateAction<any>>
) => {
  return getDoc(docRef)
    .then((docSnapshot: DocumentSnapshot<DocumentData>) => {
      videoFetchCallback(docSnapshot, setVideo);
    })
    .catch((error) => console.log(error));
};

export const getVideoByIdAsync = async (
  id: string,
  setVideo: Dispatch<SetStateAction<any>>
) => {
  return getDoc(doc(db, "videos", `${id}`))
    .then((docSnapshot: DocumentSnapshot<DocumentData>) => {
      videoFetchCallback(docSnapshot, setVideo);
    })
    .catch((error) => console.log(error));
};

const videoFetchCallback = (
  docSnapshot: DocumentSnapshot<DocumentData>,
  setVideo: Dispatch<SetStateAction<any>>
) => {
  if (setVideo) {
    setVideo(docSnapshot.data());
  } else {
    return docSnapshot.data();
  }
};
