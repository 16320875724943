import {
  doc,
  DocumentData,
  DocumentSnapshot,
  getDoc,
} from "firebase/firestore";
import { Dispatch, SetStateAction } from "react";
import { db } from "./firebase";

export const getModuleAsync = async (
  moduleId: string,
  setModule: Dispatch<SetStateAction<any>>
) => {
  return getDoc(doc(db, "modules", `${moduleId}`))
    .then((docSnapshot: DocumentSnapshot<DocumentData>) => {
      setModule(docSnapshot.data());
    })
    .catch((error) => {
      console.log(error);
    });
};
