import {
  DocumentData,
  QueryDocumentSnapshot,
  QuerySnapshot,
} from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppState";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { suggestedPlaybooks } from "../../helpers/registration";
import { captureSiteEvent, snapshotUserPlaybookByTemplateId } from "../../services/firestore";
import "./LearningPath.css";
import { assignUserPlaybookAsync } from "./utils";

const suggestedPlaybookIdToImageName: any = {
  GBRv3NGcsdfMKngPgUUQ: {
    toAdd: require("../../assets/images/modules/how-to-start-investing-add.png"),
    toRemove: require("../../assets/images/modules/how-to-start-investing-remove.png"),
  },
  "2hAT3MC52lH6cAcvsjfh": {
    toAdd: require("../../assets/images/modules/pay-off-credit-card-debt-add.png"),
    toRemove: require("../../assets/images/modules/pay-off-credit-card-debt-remove.png"),
  },
  xZWKWIJ4dWOScJfDa59H: {
    toAdd: require("../../assets/images/modules/boost-your-credit-score-add.png"),
    toRemove: require("../../assets/images/modules/boost-your-credit-score-remove.png"),
  },
};

const LearningPath = ({ userEmail }: { userEmail: string }) => {
  const { isMobile } = useContext(AppContext);
  const [hasExistingPlaybooks, setHasExistingPlaybooks] =
    useState<boolean>(false);
  const [hasAddedPlaybook, setHasAddedPlaybook] = useState<boolean>(false);

  return (
    <div
      style={{
        minWidth: isMobile ? "100px" : "600px",
        width: "100%",
        height: "100vh",
        marginTop: "auto",
        marginBottom: "auto",
        display: "flex",
        flexDirection: "column",
        alignContent: "flex-start",
      }}
    >
      <div className={"persona-intro"} style={{ marginTop: 75 }}>
        {hasExistingPlaybooks ? (
          <>
            You wanted to learn these{" "}
            <span className={"gradientColoredText"}>playbooks</span>.
          </>
        ) : (
          <>
            Lastly, we identified some{" "}
            <span className={"gradientColoredText"}>playbooks</span> you might
            want to learn.
          </>
        )}
        <br />
        Add or remove them to teach us what you like.
        <br />
        They'll be waiting for you in the app.
      </div>
      <div className={`playbook-adder-container`}>
        {suggestedPlaybooks.map((templateId: any) => {
          return (
            <SelectModuleCard
              key={templateId}
              templateId={templateId}
              userEmail={userEmail}
              hasExistingPlaybooks={hasExistingPlaybooks}
              hasAddedPlaybook={hasAddedPlaybook}
              setHasExistingPlaybooks={setHasExistingPlaybooks}
              setHasAddedPlaybook={setHasAddedPlaybook}
            />
          );
        })}
      </div>
    </div>
  );
};

export default LearningPath;

const SelectModuleCard = ({
  templateId,
  userEmail,
  hasExistingPlaybooks,
  hasAddedPlaybook,
  setHasExistingPlaybooks,
  setHasAddedPlaybook,
}: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [userInteracted, setUserInteracted] = useState<boolean>(false);
  const [selected, setSelected] = useState<boolean>(false);
  const [userPlaybookId, setUserPlaybookId] = useState<string>("");

  useEffect(() => {
    snapshotUserPlaybookByTemplateId(
      userEmail,
      templateId,
      (querySnapshot: QuerySnapshot<DocumentData>) => {
        if (querySnapshot.size) {
          if (!hasExistingPlaybooks) {
            setHasExistingPlaybooks(true);
          }
          querySnapshot.forEach(
            (docSnapshot: QueryDocumentSnapshot<DocumentData>) => {
              if (docSnapshot.exists()) {
                setUserPlaybookId(docSnapshot.id);
                setSelected(!docSnapshot.data()?.draft || false);
              }
            }
          );
        }
      }
    );
  }, []);

  useEffect(() => {
    if (userInteracted) {
      setLoading(true);
      if (!hasAddedPlaybook && !hasExistingPlaybooks) {
        captureSiteEvent('add_playbooks', userEmail);
        setHasAddedPlaybook(true);
      }
      assignUserPlaybookAsync(
        userEmail,
        templateId,
        userPlaybookId,
        !selected
      ).then((success: boolean) => {
        if (success) {
          setLoading(false);
        }
      });
    }
  }, [userInteracted, selected]);

  return (
    <div
      className={`playbook-image-container`}
      onClick={() => {
        if (!userInteracted) {
          setUserInteracted(true);
        }
        if (!loading) {
          setSelected(!selected);
        }
      }}
    >
      <img
        className={`playbook-image`}
        src={
          selected
            ? suggestedPlaybookIdToImageName[templateId].toRemove
            : suggestedPlaybookIdToImageName[templateId].toAdd
        }
      />
      {loading && (
        <div
          style={{
            position: "absolute",
          }}
        >
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
};
