import axios from "axios";
import { convertSRTtoSubtitlesArray } from "../utils/subtitles";
import { storage } from "./firebase";
import { ref, getDownloadURL } from "firebase/storage";
import { Dispatch, SetStateAction } from "react";

export const downloadSubtitlesAsync = async (
  uri: string,
  setSubtitles: Dispatch<SetStateAction<any>>
) => {
  return getDownloadURL(ref(storage, uri)).then((signedVideoUrl: string) => {
    return axios
      .get(signedVideoUrl)
      .then(({ data }: any) => {
        setSubtitles(convertSRTtoSubtitlesArray(data));
      })
      .catch((error: any) => console.log(error));
  });
};
