import { useContext } from "react";
import { AppContext } from "../../AppState";
import "./MeetYourCoach.css";

const MeetYourCoach = ({
  hasPersona
} : {
  hasPersona: string
}) => {
  const { isMobile } = useContext(AppContext);

  return (
    <div style={{
      minWidth: isMobile ? "100px" : "600px",
      width: '100%',
      height: '100vh',
      marginTop: 'auto',
      marginBottom: 'auto',
    }}>
        <div className={"persona-intro"} style={{marginTop: 75}}>
          {`We ${hasPersona ? "also" : ""} found you the perfect`} <span className={'gradientColoredText'}>Parthean Coach.</span>
          <br />
          One subscription.
          <br />
          Call or chat, any time.
        </div>
        {/* <CoachCard /> */}
        <img
          src={`${require(`../../assets/images/coach_drew.png`)}`}
          alt="Coach Bio"
          style={{width: '100%'}}
        />
    </div>
  );
};

export default MeetYourCoach;
