import { DocumentData, DocumentReference } from "firebase/firestore";
import { useEffect, useState } from "react";
import { IoArrowBack, IoArrowForward, IoPause, IoPlay } from "react-icons/io5";
import { downloadSubtitlesAsync } from "../../services/subtitles";
import { findCurrentText } from "../../utils/subtitles";
import CircleButton from "../CircleButton/CircleButton";
import "./Video.css";

import ReactHlsPlayer from "react-hls-player";
import useVideo from "../../hooks/useVideo";


/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */

 function iOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}
const isOnMobileOS = () => {
  var userAgent = navigator.userAgent || navigator.vendor;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
      return true;
  }

  if (/android/i.test(userAgent)) {
      return true;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (iOS()) {
      return true;
  }

  return false;
}

const Video = ({
  id = "",
  docRef,
  onBackClick,
  onForwardClick,
  moduleProgress = "",
}: {
  id?: string;
  docRef?: DocumentReference<DocumentData>;
  onBackClick: any;
  onForwardClick: any;
  moduleProgress?: string;
}) => {
  const {
    video,
    playerRef,
    muted,
    toggleMute,
    currentTime,
    playing,
    playVideo,
    onPlay,
    onPause,
    onVideoEnd,
    onDurationChange,
    onTimeUpdate,
    fraction,
  } = useVideo(id, docRef, onForwardClick);

  return (
    <div className={`video-container`} style={{ position: "relative", flex:1, width: '100%'}}>
      <ReactHlsPlayer
        src={`https://stream.mux.com/${video?.mux_playback_id}.m3u8`}
        autoPlay
        controls={false}
        width="100%"
        height="auto"
        playerRef={playerRef}
        onPlay={onPlay}
        onPause={onPause}
        onTimeUpdate={onTimeUpdate}
        onDurationChange={onDurationChange}
        onEnded={onVideoEnd}
        onClick={playVideo}
        playsInline={isOnMobileOS()}
      />
      {playing ? null : (
        <CircleButton
          style={{
            position: "absolute",
            zIndex: 1000,
            left: `${390 / 2 - 100 / 2}px`,
            top: `${40}%`,
            backgroundColor: "#00000060",
            color: "#ffffffee",
            border: "1px solid #ffffff80",
          }}
          size={100}
          iconProps={{ size: 36 }}
          onClick={playVideo}
          Icon={playing ? IoPause : IoPlay}
        />
      )}
      <div style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%'
      }}>
        <div className={`video-subtitles-container`}>
          <Subtitles uri={video?.subtitles} timeStamp={currentTime * 1000} />
          <div className={`video-progress-bar-container`}>
            {(onBackClick ) ? (
              <CircleButton
                onClick={onBackClick}
                Icon={IoArrowBack}
                iconProps={{ size: 32 }}
                size={42}
                style={{ color: '#1E1E21', backgroundColor: '#9BA0A5' }}
              />
            ) : null}

            <VideoProgressBar
              fraction={fraction}
              videosWatchedFraction={moduleProgress}
            />
            {onForwardClick ? (
              <CircleButton
                onClick={onForwardClick}
                Icon={IoArrowForward}
                iconProps={{ size: 32, color: "#ffffff" }}
                className={`circle-button-green`}
                size={42}
              />
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            {/* <CircleButton
              style={{ alignSelf: "center" }}
              onClick={playVideo}
              Icon={playing ? IoPause : IoPlay}
            /> */}
            {/* <CircleButton onClick={toggleMute} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Video;

const VideoProgressBar = ({ fraction, videosWatchedFraction }: any) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "relative",
        margin: "0px 8px",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "4px",
          backgroundColor: "#C2C2C280",
          borderRadius: "2px",
        }}
      >
        <div
          style={{
            width: `${fraction > 1 ? 100 : fraction * 100}%`,
            height: "100%",
            backgroundColor: "#13BA80",
            borderRadius: "2px",
          }}
        />
      </div>
      {videosWatchedFraction ? (
        <p
          style={{
            fontSize: "18px",
            color: "#ffffff",
            position: "absolute",
            right: 0,
            top: "10px",
            fontFamily: 'Bebas Neue'
          }}
        >
          {videosWatchedFraction}
        </p>
      ) : null}
    </div>
  );
};

const Subtitles = ({ uri, timeStamp }: any) => {
  const [currentText, setCurrentText] = useState<string>("");
  const [subtitles, setSubtitles] = useState<Array<any>>([]);
  useEffect(() => {
    if (uri) {
      downloadSubtitlesAsync(uri, setSubtitles);
    }
  }, [uri]);

  useEffect(() => {
    setCurrentText(findCurrentText(subtitles, timeStamp));
  }, [timeStamp]);

  return (
    <div
      style={{
        height: "42px",
        padding: "8px 4px",
        marginBottom: "6px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <p style={styles.subtitles}>{currentText}</p>
    </div>
  );
};

const styles: any = {
  subtitles: {
    fontSize: "15px",
    color: "#ffffff",
    overflowWrap: "break-word",
    overflow: "clip",
    textOverflow: "wrap",
  },
};
