import "./CircleButton.css";

const CircleButton = ({
  label = "",
  size = 32,
  Icon,
  iconProps,
  className = "",
  style,
  ...props
}: any) => {
  return (
    <button
      className={`circle-button ${className}`}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: `${size / 2}px`,
        ...style,
      }}
      {...props}
    >
      {Icon ? <Icon {...iconProps} /> : label}
    </button>
  );
};

export default CircleButton;
