import { useNavigate } from "react-router-dom";
import { captureSiteEvent } from "../../services/firestore";
import GetStartedButton from "../GetStartedButton/GetStartedButton";
import "./ModuleGetStarted.css";

const ModuleGetStarted = ({ imageUrl = "" }: { imageUrl: string }) => {
  const navigate = useNavigate();

  return (
    <div
      className={`video-container module-get-started-container`}
      style={{
        // backgroundImage: `url(${imageUrl})`,
        backgroundColor: 'transparent',
        position: "relative",
        flex: 1,
        width: "100%",
      }}
    >
      <img src={imageUrl} style={{
        width: '100%',
        height:'100%',
        objectFit: 'cover',
        position: 'absolute',
        left: 0,
        top: 0,
      }}/>
      <div className={`module-get-started-card`}>
        <div style={{ flex: 1, backgroundColor: 'transparent' }} />
        <h2 className={`module-get-started-header`}>Enjoying Parthean?</h2>
        <p style={styles.text}>
          Sign up to enjoy our growing library of over 100 modules.
          One week free, no obligations!
        </p>
        <GetStartedButton
          style={{ margin: "20px 24px" }}
          onClick={() => {
            captureSiteEvent('click_get_started')
            window.parent.location.href = "https://app.parthean.com/sign-up";
          }}
        />
      </div>
    </div>
  );
};

export default ModuleGetStarted;

const styles = {
  text: {
    fontSize: '16px',
    paddingLeft: '40px',
    paddingRight: '40px',
    fontWeight: 'normal',
    lineHeight: '19px',
    color: 'white',
    marginBottom: '22px'
  },
};
