import ReactPixel from 'react-facebook-pixel';

const PIXEL_ID = '680078280151591';

export const setupPixel = (email?: string) => {
  let advancedMatching : any = {
  };
  if (email != null) {
    // give pixel the userEmail to match them with someone that clicked an ad.
    advancedMatching['em'] = email; 
  }
  
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
  ReactPixel.init(PIXEL_ID, advancedMatching, options);
  return ReactPixel;
}