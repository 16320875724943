import { DocumentData, DocumentReference } from "firebase/firestore";
import { useCallback, useEffect, useRef, useState } from "react";
import { getVideoByIdAsync, getVideoByRefAsync } from "../services/videos";

const useVideo = (
  id?: string | undefined,
  docRef?: DocumentReference<DocumentData> | undefined,
  nextVideo?: any
) => {
  const [video, setVideo] = useState<any>();
  const [videoDuration, setVideoDuration] = useState<number>(1);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [playing, setPlaying] = useState(false);

  const playerRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (id) {
      getVideoByIdAsync(id, setVideo);
    } else if (docRef) {
      getVideoByRefAsync(docRef, setVideo);
    }
  }, [id, docRef]);

  const [muted, setMuted] = useState<boolean>(true);

  const onPlay = useCallback(() => {
    setPlaying(true);
  }, []);

  const onPause = useCallback(() => {
    setPlaying(false);
  }, []);

  const onVideoEnd = useCallback(() => {
    setPlaying(false);
    nextVideo();
  }, [nextVideo]);

  const onDurationChange = useCallback(() => {
    setVideoDuration(playerRef?.current?.duration || 1);
  }, [docRef]);

  const onTimeUpdate = useCallback(() => {
    setCurrentTime(playerRef?.current?.currentTime || 0);
  }, [docRef, videoDuration]);

  const playVideo = useCallback(() => {
    if (playerRef?.current?.paused) {
      playerRef?.current?.play();
      setPlaying(true);
    } else {
      playerRef?.current?.pause();
      setPlaying(false);
    }
  }, [playerRef]);

  const toggleMute = () => {
    setMuted(!muted);
  };

  const fraction: number = currentTime / videoDuration;

  return {
    video,
    playerRef,
    muted,
    toggleMute,
    currentTime,
    playing,
    playVideo,
    onPlay,
    onPause,
    onVideoEnd,
    onDurationChange,
    onTimeUpdate,
    fraction,
  };
};

export default useVideo;
