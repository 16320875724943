import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ModuleGetStarted from "../../components/ModuleGetStarted/ModuleGetStarted";
import Video from "../../components/Video/Video";
import useQuery from "../../hooks/useQuery";
import { getModuleAsync } from "../../services/modules";

const Module = () => {
  const { id }: any = useParams();
  const query = useQuery();
  const [module, setModule] = useState<any>({});
  const [index, setIndex] = useState<number>(0);

  const numVideos: number = module?.lessons?.length || 0;
  const displayIndex: number = index + 1;

  useEffect(() => {
    if (id) {
      getModuleAsync(id, setModule);
    }
  }, [id]);

  const goToPreviousVideo = () => {
    if (displayIndex > 1) setIndex(index - 1);
  };
  const goToNextVideo = () => {
    if (displayIndex < numVideos) setIndex(displayIndex);
  };

  return !query.get("view_token") && index > 0 ? (
    <ModuleGetStarted imageUrl={module?.thumbnailURL || module?.cardImageURL} />
  ) : (
    <Video
      docRef={module?.lessons ? module?.lessons[index] : undefined}
      onBackClick={goToPreviousVideo}
      onForwardClick={goToNextVideo}
      moduleProgress={`${displayIndex}/${numVideos}`}
    />
  );
};

export default Module;
