import { useContext, useState } from "react";
import { AppContext } from "../../AppState";
import DealCard from "./components/DealCard/DealCard";
import "./StartFreeTrial.css";

const allDealInfo: any = [
  {
    stripeURL: "https://buy.stripe.com/bIY9EAfdM9PY7HG4gq",
    title: "Unlimited_Live_Coaching_Yearly",
    value: 480,
    period: "year",
    subValue: 40,
    subPeriod: "month",
    discount: 18,
    descriptionList: [
      { text: "Parthean Coach", subtext: "Unlimited expertise" },
      {
        text: "Unlimited Chat & Video Calls",
        subtext: "Financial support, any time",
      },
      { text: "Parthean App", subtext: "Simple & Powerful tools" },
      { text: "Parthean Library", subtext: "100+ learning Modules" },
    ],
    footer: "TRY ONE WEEK FREE",
  },
  {
    stripeURL: "https://buy.stripe.com/28o184d5EbY6bXW14d",
    title: "Unlimited_Live_Coaching_Monthly",
    value: 49,
    period: "month",
    subValue: 588,
    subPeriod: "year",
    descriptionList: [
      { text: "Parthean Coach", subtext: "Unlimited expertise" },
      {
        text: "Unlimited Chat & Video Calls",
        subtext: "Financial support, any time",
      },
      { text: "Parthean App", subtext: "Simple & Powerful tools" },
      { text: "Parthean Library", subtext: "100+ learning Modules" },
    ],
    footer: "TRY ONE WEEK FREE",
  },
]

const StartFreeTrial = () => {
  const { isMobile } = useContext(AppContext);

  return (
    <div style={{
      minWidth: isMobile ? "100px" : "600px",
      width: '100%',
      height: '100vh',
      marginTop: 'auto',
      marginBottom: 'auto',
    }}>
      <div className={"persona-title"} style={{marginTop: 75}}>
        Let's get started.
        <br/>
        Choose a plan,  {isMobile && <br />} get a week for <span className={'gradientColoredText'}>free.</span>
      </div>
      <div className={`freetrial-deals`}>
        
        {allDealInfo.map((deal: any) => {
          return (
          <img
            key={deal.title}
            src={`${require(`../../assets/images/${deal.title}.png`)}`}
            alt={deal.title}
            className={'deal-card-img'}
            onClick={() => {
              window.location.href = deal.stripeURL;
            }}
          />
          );
        })}
      </div>
      <div style={{
        paddingTop: 12,
        paddingBottom: '20vh'
      }}>
        <p className={`freetrial-body`}>
          You’ll be brought to an external Stripe payment page,
          <br />
          then brought back here.
        </p>
      </div>
    </div>
  );
};

export default StartFreeTrial;
