import { useContext, useEffect, useState } from "react";
import { Bar, BarChart, Label, XAxis, YAxis } from "recharts";
import { AppContext } from "../../AppState";
import "./Calculators.css";

const R: number = 0.08; // Annual interest return
const WEEKS_IN_YEAR: number = 52;
const NUM_INVESTING_YEARS: number = 20;

const calculateEarningsFromInvestments = (savings: number, years: number) => {
  // Uses the formula A = C * ((1.08)^5 - 1) / 0.08
  return Math.floor((savings * (Math.pow(1 + R, years) - 1)) / R);
};

const DeliveryCalculator = () => {
  const { isMobile } = useContext(AppContext);
  const [spent, setSpent] = useState<number>(0);
  const [earnings, setEarnings] = useState<Array<any>>([]);

  const windowWidth: number = window.innerWidth;

  useEffect(() => {
    const yearlySpending: number = spent * WEEKS_IN_YEAR;
    const earningsByYear: any[] = [];
    for (let i = 1; i <= NUM_INVESTING_YEARS; i++) {
      earningsByYear.push({
        earning: calculateEarningsFromInvestments(yearlySpending, i),
        year: i,
      });
    }
    setEarnings(earningsByYear);
  }, [spent]);

  return (
    <>
      <img
        src={`${require("../../assets/images/logo_white_with_icon.png")}`}
        alt="Parthean Logo Text"
        className={`parthean-img-size`}
      />
      <div className="container" style={{ width: isMobile ? "80vw" : "50vw" }}>
        <div className="calculator-container">
          <div className="header">
            <div style={{ fontSize: isMobile ? "20px" : "22px", }}>
              How much do you
            </div>
            <div style={{ fontSize: isMobile ? "20px" : "22px", }}>
              <span className="gradient-text"> spend on takeout</span> each
              week?
            </div>
            <div style={{ alignSelf: "center", width: "60%" }}>
              <input
                type="range"
                min="0"
                max="250"
                name="initial"
                className="input-form"
                defaultValue={spent}
                onChange={(event) => {
                  if (
                    event.target.value &&
                    Number(event.target.value) !== NaN
                  ) {
                    setSpent(Number(event.target.value));
                  }
                }}
                style={{ marginTop: isMobile ? "24px" : "36px" }}
              />
              <div style={{ textAlign: "center" }}>${spent}</div>
            </div>
          </div>
          <div
            className="calculated-value"
            style={{ margin: isMobile ? "-24px" : "-16px" }}
          >
            <div style={{ fontSize: isMobile ? "20px" : "22px", }}>
              If you <span className="gradient-text">invest that money</span>{" "}
              instead, you'd have
            </div>
            <div style={{ fontSize: "64px" }}>
              {earnings.length > 9
                ? `$${earnings[9]["earning"].toLocaleString()}`
                : "$0"}
            </div>
            <div>
              in 10 years*
            </div>
            <BarChart
              width={windowWidth > 700 ? 680 : windowWidth / 1.2}
              height={320}
              data={earnings}
              margin={{
                top: 30,
                right: 40,
                left: 60,
                bottom: 30,
              }}
              style={{ alignSelf: "center" }}
            >
              <defs>
                <linearGradient id="colorUv" x1="1" y1="1" x2="0" y2="0">
                  <stop offset="0%" stopColor="#3FD1DA" stopOpacity={1} />
                  <stop offset="100%" stopColor="#4AD758" stopOpacity={1} />
                </linearGradient>
              </defs>
              <XAxis fontSize={20} dataKey="year" interval={isMobile ? 2 : 1}>
                <Label
                  value="Years of Investing"
                  offset={-24}
                  position="insideBottom"
                  fontSize={18}
                  fill="white"
                  opacity={0.5}
                />
              </XAxis>
              <YAxis
                fontSize={20}
                tickFormatter={(value) => `\$${value.toLocaleString()}`}
                domain={[0, 600000]}
              />
              <Bar dataKey="earning" fill="url(#colorUv)" opacity={0.9} />
            </BarChart>
          </div>
          <div className="footer-container">
            <div>
              * Assuming an annual return rate of 8%, the 30 year average
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeliveryCalculator;
