const personaScoring: any = {
  q5: {
    question: 'When it comes to paying bills, I:',
    answers: [
      {
        text: 'Pay reliably and on time',
        spendingMoneyScore: 0,
      },
      {
        text: 'Feel nervous about having enough money',
        spendingMoneyScore: 1,
      },
      {
        text: 'Sometimes fall behind',
        spendingMoneyScore: 2,
      },
    ],
  },
  q6: {
    question: 'If I magically got $10,000, I’d first:',
    answers: [
      {
        text: 'Spend it',
        makingMoneyScore: 0,
        spendingMoneyScore: 1,
      },
      {
        text: 'Save it',
        makingMoneyScore: 1,
        spendingMoneyScore: -1,
      },
      {
        text: 'Invest in something risky',
        makingMoneyScore: 3,
      },
      {
        text: 'Invest in something less risky',
        makingMoneyScore: 2,
      },
      { 
        text: 'Pay down debt', 
        makingMoneyScore: 0, 
        spendingMoneyScore: -1,
      },
      {
        text: 'Not sure',
        makingMoneyScore: -1,
      },
    ],
  },
  q7: {
    question: 'If I wanted a luxury item, I’d:',
    answers: [
      {
        text: 'Buy it with cash I already have',
        spendingMoneyScore: 1,
      },
      {
        text: 'Buy it on credit/loan and pay later',
        spendingMoneyScore: 2,
      },
      {
        text: 'Save money to buy it down the road',
        spendingMoneyScore: 0,
      },
      { 
        text: 'I would likely never buy it', 
        spendingMoneyScore: -1 
      },
    ],
  },
  q8: {
    question: 'To feel happy with my income, ',
    answers: [
      {
        text: 'I need to make way more',
        makingMoneyScore: 3,
      },
      {
        text: 'I need to make slightly more',
        makingMoneyScore: 2,
      },
      {
        text: 'I’m happy with how much I make',
        makingMoneyScore: 1,
      },
      {
        text: 'I’m more than happy with my income',
        makingMoneyScore: 0,
      },
    ],
  },
  q9: {
    question: 'When it comes to investing I’d prefer:',
    answers: [
      {
        text: 'High-risk, high-reward',
        makingMoneyScore: 3,
      },
      {
        text: 'Less risky investments',
        makingMoneyScore: 2,
      },
      { 
        text: 'Keeping money in savings', 
        makingMoneyScore: 1,
      },
      {
        text: 'Hiring a trusted advisor',
        makingMoneyScore: -1,
      },
      {
        text: 'Not sure',
        makingMoneyScore: 0,
      },
    ],
  },  
};

const scoreToPersona: any = {
  "SAVER-RESERVED": "Economical",
  "SAVER-CAUTIOUS": "Modest",
  "SAVER-BALANCED": "Steadfast",
  "SAVER-OPPORTUNIST": "Resourceful",
  "ACCUMULATOR-RESERVED": "Strategic",
  "ACCUMULATOR-CAUTIOUS": "Responsible",
  "ACCUMULATOR-BALANCED": "Prudent",
  "ACCUMULATOR-OPPORTUNIST": "Vigilant",
  "SPENDER-RESERVED": "Unconcerned",
  "SPENDER-CAUTIOUS": "Skeptical",
  "SPENDER-BALANCED": "Deliberate",
  "SPENDER-OPPORTUNIST": "Adventurous",  
};

export const calculatePersona = (quizResponses: any) => {
  if (!Object.keys(quizResponses).length) {
    return false;
  }
  const scores = {
    spendingMoneyScore: 0,
    spendingMoneyQuestions: 0,
    makingMoneyScore: 0,
    makingMoneyQuestions: 0,
  }

  Object.keys(quizResponses).forEach(
    (questionNumber) => {
      const response = quizResponses[questionNumber];
      const { question, answers } = personaScoring[questionNumber];
      if (!question || !answers ) return;
      
      for (let answerInd = 0; answerInd < answers.length; answerInd += 1) {
        const answer = answers[answerInd];
        if (response === answer['text']) {
          if (answer['spendingMoneyScore']) {
            scores['spendingMoneyScore'] += answer['spendingMoneyScore'];
            scores['spendingMoneyQuestions'] += 1;
          }
          if (answer['makingMoneyScore']) {
            scores['makingMoneyScore'] += answer['makingMoneyScore'];
            scores['makingMoneyQuestions'] += 1;
          }
        }
      }
    }
  );

  const spendingMoneyFinalScore = scores['spendingMoneyScore'] / scores['spendingMoneyQuestions'];
  const makingMoneyFinalScore = scores['makingMoneyScore'] / scores['makingMoneyQuestions'];
  const spenderType = determineSpenderType(spendingMoneyFinalScore);
  const makerType = determineMakerType(makingMoneyFinalScore);
  return scoreToPersona[spenderType.concat("-").concat(makerType)];
};

const determineSpenderType = (value: number) => {
  switch (true) {
    case value < -0.3:
      return "SAVER";
    case value < 0.3:
      return "ACCUMULATOR";
    default:
      return "SPENDER";
  }
};

const determineMakerType = (value: Number) => {
  switch (true) {
    case value < -0.5:
      return "RESERVED";
    case value < 1.5:
      return "CAUTIOUS";
    case value < 2.5:
      return "BALANCED";
    default:
      return "OPPORTUNIST";
  }
};
