import PartheanModal from "../../components/PartheanModal/PartheanModal";
import "./Auth.css";

const Auth = () => {
  return (
    <PartheanModal
      content={
        <a
          href="https://app.parthean.com/auth"
        >
          <button
            className={`coach-cta-button auth-button`}
          >
            <p className={`peach-purple-gradient-text`}>
              RETURN TO PARTHEAN APP
            </p>
          </button>
        </a>
      }
    />
  );
};
export default Auth;
