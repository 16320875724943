import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import DownloadApp from "./screens/DownloadApp/DownloadApp";
import SignUp from "./screens/SignUp/SignUp";
import { BrowserRouter as HashRouter, Routes, Route } from "react-router-dom";
import Auth from "./screens/Auth/Auth";
import QuizResults from "./screens/QuizResults/QuizResults";
import Module from "./screens/Module/Module";
import DeliveryCalculator from "./screens/Calculators/DeliveryCalculator";
import PersonaQuiz from "./screens/PersonaQuiz/PersonaQuiz";

// SITE FLOW:
// sign-up is the entry point from webflow,
// redirects to either typeform or get-started w/o url params

// typeform redirects to get-started with url params,
// redirects to stripe

// stripe redirects to download with a fake key hash.

// modules get rendered in an iframe on parthean.com

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <HashRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/auth" element={<Auth />} />
          <Route path="sign-up" element={<SignUp />} />
          <Route path="get-started" element={<QuizResults />} />
          <Route path="download" element={<DownloadApp />} />
          <Route path="modules/:id" element={<Module />} />
          <Route path="calculators/delivery" element={<DeliveryCalculator />} />
          <Route path="persona-quiz" element={<PersonaQuiz />} />
        </Route>
      </Routes>
    </HashRouter>
  </React.StrictMode>
);
