// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCwqOZn1pjCnOzeZMShlOWJUphSm2bH4i8",
  authDomain: "parthean-platform.firebaseapp.com",
  databaseURL: "https://parthean-platform.firebaseio.com",
  projectId: "parthean-platform",
  storageBucket: "parthean-platform.appspot.com",
  messagingSenderId: "327693097140",
  appId: "1:327693097140:web:a84132ace08c10e4849fe1",
  measurementId: "G-S0Y0866VSQ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export { app, db, storage };
